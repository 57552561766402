/*! core.scss | Bulkit | CSS Ninja */

/* ==========================================================================
CSS Core
========================================================================== */

@import "partials/theme";
@import "partials/fonts";
@import "partials/animations";
@import "partials/pageloader";
@import "partials/one-page-1";
@import "partials/pricing";
@import "partials/navbar";
@import "partials/sections";
@import "partials/buttons";
@import "partials/forms";
@import "partials/navigation";
@import "partials/utilities";
@import "partials/responsive";

.navbar-item img {
    max-height: 2.75rem;
}

.footer .footer-logo {
    max-width:100%;
}

footer a {
     text-decoration: underline;
}

#contact {
    padding-top:80px
}

#contact-column {
    padding:30px;
    padding-top:0;
    margin-bottom:0;
}

#contact-column > div:nth-child(1) > div {
    margin-right:5px
}

.ml-form-recaptcha{width:300px;margin:0 auto}.ml-form-recaptcha.ml-error iframe{border:solid 1px red}
@media screen and (max-width:768px){
    .ml-form-recaptcha{width:231px;transform:scale(.77);-webkit-transform:scale(.77);transform-origin:0 0;-webkit-transform-origin:0 0}
}

#main-features {
    opacity:0
}